import {getCoords} from './utils';

// navsSelector
// --- navsSelector__item .active data-number
// tabsSelector
// --- tabsSelector__item .active data-number
// settings{
//     scroll: 1 || 0
// }

const tabs = (navsSelector, tabsSelector, settings) => {
    const navs = document.querySelector(`.${navsSelector}`);
    const tabs = document.querySelector(`.${tabsSelector}`);

    const toggleActive = (element) => {
        element.classList.toggle('active');
    }

    const switchTabs = (e) => {
        // Если таб активен ничего не делаем
        if (e.target.classList.contains('active')) {
            // console.log('Таб уже активен');
            return true;
        }
        if (e.target.classList.contains(`${navsSelector}__item`) || e.target.parentElement.classList.contains(`${navsSelector}__item`)) {
            switcher(e)
        } else{
            console.log('Неправильный селектор');
            return true;
        }
    }

    const switcher = (e) => {
        // console.log('Клик по навигации');
        // Переключение навигации
        toggleActive(navs.querySelector('.active'));
        toggleActive(e.target);
        // Переключение табов
        toggleActive(tabs.querySelector('.active'));
        const tabNumber = e.target.dataset.number;
        const newTab = tabs.querySelector(`[data-number="${tabNumber}"]`)
        toggleActive(newTab);
        settings.scroll === 1 && window.scrollTo(0, getCoords(newTab).top)
    }

    if (navs) {
        navs.addEventListener('click', switchTabs, false);
    } else {
        // console.log('Табов не обнаружено');
    }
}

export default tabs;