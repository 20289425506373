const trunk = () => {
    const items = document.querySelectorAll('.trunk-slide');
    const content = document.querySelector('.trunk-content');
    const toggleBtn = document.querySelector('#trunkNav');
    const toggleFiltrtBtn = document.querySelector('#trunkFilter');
    const toggleCatalogBtn = document.querySelector('#trunkCatalog');
    const triggers = ['trunkNav', 'trunkFilter', 'trunkCatalog'];
    const open = (selector) => {
        const nav = document.querySelector(`.${selector}`);
        nav.classList.add('open');
        nav.classList.remove('close');
        content.classList.add('open');
        content.classList.remove('close');
        const trigger = document.querySelector(`#${selector}`);
        trigger.classList.add('active');
    };
    const close = () => {
        items.forEach((item) => {
            item.classList.add('close');
            item.classList.remove('open');
        });
        toggleBtn.classList.remove('active');
        toggleFiltrtBtn.classList.remove('active');
        toggleCatalogBtn.classList.remove('active');
    };
    triggers.forEach((triggerSelector) => {
        const trigger = document.querySelector(`#${triggerSelector}`);
        if (!trigger)
            return false;
        trigger.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();
            console.log(event.target);
            if (content.classList.contains('open')) {
                close();
                return true;
            }
            if (event.target.nodeName === 'I') {
                open(event.target.parentElement.id);
            }
            else if (event.target.nodeName === 'SPAN') {
                open(event.target.parentElement.id);
            }
            else {
                open(event.target.id);
            }
        });
    });
    const contentClose = () => {
        if (content.classList.contains('open')) {
            close();
        }
    };
    content && content.addEventListener('click', contentClose);
};
export default trunk;
