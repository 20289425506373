import { $$ } from './utils';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
const fancy = (selectors) => {
    Fancybox.bind('[data-fancybox="gallery"]', {});
    selectors.forEach(item => {
        $$(`.${item.trigger}`).forEach((trigger) => {
            const form = document.querySelector(`#${item.form}`);
            const header = form.querySelector('.modal-form__title__header');
            const desc = form.querySelector('.modal-form__title__desc');
            trigger.on('click', () => {
                // Изменить модальное окно 
                header ? header.innerHTML = item.header : '';
                if (desc) {
                    if (item.desc) {
                        desc.classList.remove('hide');
                        desc.innerHTML = item.desc;
                    }
                    else {
                        desc.classList.add('hide');
                    }
                }
                // Показать модальное коно с формой
                Fancybox.show([{
                        src: `#${item.form}`
                    }]);
            });
        });
    });
};
export default fancy;
