import { AntiSpam } from './';
const initAntiSpam = (formSelector, excludeForms) => {
    //Найти все формы на странице
    const forms = document.querySelectorAll(formSelector);
    if (!forms)
        return;
    // Запустить валидацию для каждой формы.
    // Question: How make better
    // ([...forms] as HTMLFormElement[])
    // Array.from(forms)
    // ([...forms as unknown as []])
    Array.prototype.slice.call(forms)
        .filter((form) => !excludeForms.some((selector) => form.classList.contains(selector)))
        .forEach((form) => (new AntiSpam({
        form: form
    }).init()));
};
export default initAntiSpam;
