import { inputValidate } from './';
import submiter from '../submiter';
class FormValidate {
    form;
    inputs;
    inputErrorTextSelector;
    formErrorText;
    // botErrorText: HTMLElement;
    submitBtn;
    state;
    // antiSpamCounterInput:HTMLInputElement;
    // isRobotInput:HTMLInputElement;
    constructor(props) {
        this.form = props.form;
        this.inputErrorTextSelector = props.inputErrorTextSelector;
        this.formErrorText = this.form.querySelector(props.formErrorTextSelector);
        // this.botErrorText = this.form.querySelector(props.botErrorTextSelector);
        this.submitBtn = this.form.querySelector('.submit');
        this.state = {
            formStatus: ''
        };
        // this.isRobotInput = this.form.querySelector('.isRobotInput');
    }
    checkInputs = (inputs) => {
        inputs.forEach((input) => {
            const wrapper = input.parentNode;
            const errorTextBox = wrapper.querySelector(this.inputErrorTextSelector);
            inputValidate(input, wrapper, errorTextBox);
        });
    };
    checkInput = (event) => {
        if (!event.target.classList.contains('required'))
            return true;
        const wrapper = event.target.parentNode;
        const errorTextBox = wrapper.querySelector(this.inputErrorTextSelector);
        inputValidate(event.target, wrapper, errorTextBox);
        this.updateFormStatus();
    };
    updateFormStatus = () => {
        if (this.form.querySelector('.error') || this.form.dataset.isrobot === 'true') {
            this.state.formStatus = 'invalid';
            this.formErrorText && this.formErrorText.classList.remove('hide');
            // if(this.form.dataset.isrobot === 'true'){
            //     this.botErrorText && this.botErrorText.classList.remove('hide')
            // }
        }
        else {
            this.state.formStatus = 'valid';
            this.formErrorText && this.formErrorText.classList.add('hide');
            // this.botErrorText && this.botErrorText.classList.add('hide')
        }
    };
    checkForm = () => {
        const inputs = this.form.querySelectorAll('.required');
        this.checkInputs(inputs);
        // antiSpamCheck(this.form);
        this.updateFormStatus();
    };
    submitHandler = (event) => {
        event.preventDefault();
        // const isRobotInput:HTMLInputElement = this.form.querySelector('.isRobotInput');
        // isRobotInput.value = 'false';
        this.checkForm();
        if (this.state.formStatus === 'valid') {
            submiter(this.form);
            // this.form.submit();
        }
    };
    init = () => {
        this.form.addEventListener('change', this.checkInput, false);
        this.form.addEventListener('submit', this.submitHandler, false);
        // antiSpamCounter(this.form);
        // addIsRobotCheckInput(this.form);
    };
}
export default FormValidate;
