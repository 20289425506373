const inputValidate = (input, wrapper, errorTextBox) => {
    const state = {
        status: 'undefined',
        mess: ''
    };
    const editInput = () => {
        state.status === 'valid' ? wrapper.classList.remove('error') : wrapper.classList.add('error');
        errorTextBox.innerHTML = `<span>${state.mess}</span>`;
    };
    if (input.value === '') {
        state.status = 'invalid';
        state.mess = 'Поле должно быть заполнено';
        // editInput();
        // return state;
    }
    else if (input.value.length < 2) {
        state.status = 'invalid';
        state.mess = 'Поле должно содержать больше 1го символова';
        // editInput();
        // return state;
    }
    else {
        state.status = 'valid';
        state.mess = '';
    }
    if (input.name === 'data[new][email]' || input.name === 'data[new][email2]') {
        const re = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;
        if (!re.test(input.value)) {
            state.status = 'invalid';
            state.mess = 'E-mail введён неверно';
        }
    }
    if (input.name === 'data[new][phone]') {
        const re = /^[0-9\+]{1}[\d\(\)\ -]{4,14}\d$/;
        if (!re.test(input.value)) {
            state.status = 'invalid';
            state.mess = 'Телефон введён неверно';
        }
    }
    if (input.dataset.name === 'checkbox') {
        if (input.checked === false) {
            state.status = 'invalid';
            state.mess = 'Поле должно быть отмечено';
        }
        else {
            state.status = 'valid';
            state.mess = '';
        }
    }
    editInput();
    return state;
};
export default inputValidate;
