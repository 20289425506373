export default function submiter(form) {
    const ajaxSend = async (url, formData) => {
        const fetchResp = await fetch(url, {
            method: 'POST',
            body: formData
        });
        if (!fetchResp.ok) {
            throw new Error(`Ошибка по адресу ${url}, статус ошибки ${fetchResp.status}`);
        }
        return await fetchResp.text();
    };
    const formData = new FormData(form);
    ajaxSend('/webforms/secureSend/', formData)
        .then(response => {
        processing(response);
        form.reset();
        // window.ym(56313895,'reachGoal', 'formSent');
    })
        .catch(err => console.error(err));
    const processing = (pageSourceHtml) => {
        // form?.querySelector('.modal-form__title').classList.add('hide');
        form.parentElement.previousElementSibling.classList.add('hide');
        form.parentElement.innerHTML = `
            <div class="col-24 column">
                <h2>Сообщение отправлено</h2>
                <p>В ближайшее время менеджер ответит на ваше обращение</p>
            </div>
        `;
        // pageSourceHtml.indexOf('<div class="{$col-medium} justify-center column formPostedMessege">')
    };
}
;
