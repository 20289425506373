import {$} from './utils';

// searchForm('.header__search-form', '.header__search-icon', '[data-component="header-menu"]', '.header__search-icon-close');

const searchForm = (formSelector, initBtnSelector, menuSelector, closeBtnSelector) => {
    
    const formWrapper = $(formSelector)
    const initBtn = $(initBtnSelector)
    const closeBtn = $(closeBtnSelector);
    const menu = $(menuSelector)

    const showForm = () => {
        menu.style.visibility = "hidden";
        menu.style.opacity = "0";
        menu.style.display = "none";
        
        initBtn.style.visibility = "hidden";
        initBtn.style.opacity = "0";
        initBtn.style.display = "none";
        
        formWrapper.style.visibility = "visible";
        formWrapper.style.opacity = "1";
        formWrapper.style.display = "flex";

        closeBtn.style.visibility = "visible";
        closeBtn.style.opacity = "1";
        closeBtn.style.display = "flex";
    }

    const hideForm = () => {
        menu.style.visibility = "visible";
        menu.style.opacity = "1";
        menu.style.display = "flex";
        
        initBtn.style.visibility = "visible";
        initBtn.style.opacity = "1";
        initBtn.style.display = "flex";
        
        formWrapper.style.visibility = "hidden";
        formWrapper.style.opacity = "0";
        formWrapper.style.display = "none";

        closeBtn.style.visibility = "hidden";
        closeBtn.style.opacity = "0";
        closeBtn.style.display = "none";
    }

    initBtn && initBtn.on('click', showForm);
    closeBtn && closeBtn.on('click', hideForm);
}

export default searchForm