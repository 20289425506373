class AntiSpam {
    form;
    isRobotInput;
    constructor(props) {
        this.form = props.form;
    }
    addIsRobotCheckInput = (form) => {
        const checkInput = document.createElement('input');
        checkInput.setAttribute('type', 'hidden');
        checkInput.setAttribute('class', 'isRobotInput');
        checkInput.setAttribute('name', 'isRobot');
        checkInput.setAttribute('value', '');
        form.appendChild(checkInput);
    };
    antiSpamHandler = () => {
        const isRobotInput = this.form.querySelector('.isRobotInput');
        isRobotInput.value = 'false';
    };
    init = () => {
        this.addIsRobotCheckInput(this.form);
        this.form.addEventListener('click', this.antiSpamHandler, false);
    };
}
export default AntiSpam;
